import config from '../config';

class ApiService {
  constructor() {
    this.defaultHeaders = {
      'Accept': 'application/json',
    };
    this.timeout = 30000; // 30 seconds
  }

  async fetchWithTimeout(url, options) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), this.timeout);

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });
      clearTimeout(timeoutId);
      return response;
    } catch (error) {
      clearTimeout(timeoutId);
      if (error.name === 'AbortError') {
        throw new Error('Request timeout');
      }
      throw error;
    }
  }

  async handleResponse(response) {
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || `HTTP error! status: ${response.status}`);
    }
    return data;
  }

  // Image Generation and Processing
  async processImage(imageFile, style) {
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('style', style);

    try {
      const response = await this.fetchWithTimeout(`${config.API_URL}${config.API_ENDPOINTS.PROCESS_IMAGE}`, {
        method: 'POST',
        body: formData,
      });
      return this.handleResponse(response);
    } catch (error) {
      console.error('Error processing image:', error);
      throw error;
    }
  }

  async generateImage(imageFile, params) {
    const formData = new FormData();
    formData.append('image', imageFile);
    
    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, typeof value === 'object' ? JSON.stringify(value) : value);
    });

    try {
      const response = await this.fetchWithTimeout(`${config.API_URL}${config.API_ENDPOINTS.GENERATE_IMAGE}`, {
        method: 'POST',
        body: formData,
      });
      return this.handleResponse(response);
    } catch (error) {
      console.error('Error generating image:', error);
      throw error;
    }
  }

  async generateFromText(params) {
    try {
      const response = await this.fetchWithTimeout(
        `${config.API_URL}${config.API_ENDPOINTS.GENERATE_FROM_TEXT}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      );
      
      const data = await response.json();
      
      if (!response.ok) {
        if (data.message && data.message.includes('must be related to generating a bridge')) {
          throw new Error('Please ensure your prompt is about generating a bridge image');
        }
        throw new Error(data.message || 'Failed to generate image');
      }
      
      return data;
    } catch (error) {
      console.error('Error in generateFromText:', error);
      throw error;
    }
  }

  // Gallery and Image Management
  async getRecentImages() {
    try {
      const response = await this.fetchWithTimeout(`${config.API_URL}${config.API_ENDPOINTS.GALLERY_RECENT}`);
      return this.handleResponse(response);
    } catch (error) {
      console.error('Error fetching recent images:', error);
      throw error;
    }
  }

  async handleAnonymousInteraction(browserFingerprint, imageId, interactionType) {
    try {
      const response = await this.fetchWithTimeout(
        `${config.API_URL}${config.API_ENDPOINTS.GALLERY_INTERACTIONS}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            browserFingerprint,
            imageId,
            interactionType,
          }),
        }
      );
      const data = await this.handleResponse(response);
      return data.success;
    } catch (error) {
      console.error('Error handling interaction:', error);
      throw error;
    }
  }

  async getAnonymousInteractions(browserFingerprint, imageIds) {
    try {
      const queryParams = new URLSearchParams({
        browserFingerprint,
        imageIds: imageIds.join(','),
      });

      const response = await this.fetchWithTimeout(
        `${config.API_URL}${config.API_ENDPOINTS.GALLERY_INTERACTIONS}?${queryParams.toString()}`
      );
      const data = await this.handleResponse(response);
      return data.interactions;
    } catch (error) {
      console.error('Error fetching interactions:', error);
      throw error;
    }
  }

  // Credit Management
  async getCreditInfo() {
    try {
      const response = await this.fetchWithTimeout(`${config.API_URL}${config.API_ENDPOINTS.CREDIT_INFO}`);
      
      // Check if the response is HTML (error page)
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('text/html')) {
        return this.getDefaultCreditInfo();
      }

      const data = await this.handleResponse(response);
      const userDetails = data.user_details?.[0] || {};
      const parsedRefreshDate = this.parseSafeDate(userDetails.apiPlanTokenRenewalDate);
      
      return {
        creditsUsed: (userDetails.subscriptionTokens || 150) - (userDetails.apiSubscriptionTokens || 0),
        creditsRemaining: userDetails.apiSubscriptionTokens || 0,
        monthlyLimit: userDetails.subscriptionTokens || 150,
        rolloverCredits: userDetails.apiPaidTokens || 0,
        nextRefreshDate: parsedRefreshDate
      };
    } catch (error) {
      return this.getDefaultCreditInfo();
    }
  }

  parseSafeDate(dateString) {
    if (!dateString) {
      return this.getNextRefreshDate();
    }
    
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return this.getNextRefreshDate();
      }
      return date.toISOString();
    } catch (error) {
      return this.getNextRefreshDate();
    }
  }

  getDefaultCreditInfo() {
    return {
      creditsUsed: 0,
      creditsRemaining: 100,
      monthlyLimit: 100,
      rolloverCredits: 0,
      nextRefreshDate: this.getNextRefreshDate()
    };
  }

  getNextRefreshDate() {
    const now = new Date();
    return new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      1
    ).toISOString();
  }

  /* Future Features - Commented out for later implementation
  
  // Image Editing
  async cropImage(imageId, cropData) {
    try {
      const response = await this.fetchWithTimeout(
        `${config.API_URL}${config.API_ENDPOINTS.CROP_IMAGE}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            imageId,
            ...cropData,
          }),
        }
      );
      return this.handleResponse(response);
    } catch (error) {
      console.error('Error cropping image:', error);
      throw error;
    }
  }

  // Admin Functions
  async deleteImage(imageId) {
    try {
      const response = await this.fetchWithTimeout(
        `${config.API_URL}${config.API_ENDPOINTS.DELETE_IMAGE}/${imageId}`,
        {
          method: 'DELETE',
        }
      );
      return this.handleResponse(response);
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  }

  async updateImagePrompt(imageId, newPrompt) {
    try {
      const response = await this.fetchWithTimeout(
        `${config.API_URL}${config.API_ENDPOINTS.UPDATE_PROMPT}/${imageId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ prompt: newPrompt }),
        }
      );
      return this.handleResponse(response);
    } catch (error) {
      console.error('Error updating prompt:', error);
      throw error;
    }
  }
  */

  // Add this new function
  async downloadImage(imageUrl) {
    try {
      const response = await this.fetchWithTimeout(
        `${config.API_URL}${config.API_ENDPOINTS.GALLERY_DOWNLOAD}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ imageUrl }),
        }
      );

      if (!response.ok) throw new Error('Download failed');

      // Get the blob from the response
      const blob = await response.blob();
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      
      // Set suggested filename
      link.download = 'generated-image.png';
      
      // Append to body, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the URL object
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading image:', error);
      throw error;
    }
  }
}

// Update the config object with active endpoints only
if (!config.API_ENDPOINTS) {
  config.API_ENDPOINTS = {};
}

Object.assign(config.API_ENDPOINTS, {
  GALLERY_INTERACTIONS: '/gallery/interactions',
  CREDIT_INFO: '/user/credits',
  GALLERY_DOWNLOAD: '/gallery/download',
  ...config.API_ENDPOINTS, // Preserve existing endpoints
});

const apiService = new ApiService();
export default apiService;
