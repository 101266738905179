import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LeonardoPage from './pages/LeonardoPage';
import PromptGuide from './pages/PromptGuide';
import GalleryPage from './pages/GalleryPage';
import Navbar from './components/Navbar';
import CreditTracker from './components/CreditTracker';
import { useCredits } from './hooks/useCredits';
import './App.css';

function AppContent() {
  const { refreshCredits } = useCredits();
  const location = useLocation();

  React.useEffect(() => {
    refreshCredits();
  }, [location, refreshCredits]);

  useEffect(() => {
    let currentOffset = 0;
    let targetOffset = 0;
    const smoothFactor = 0.05;
    const oscillationSpeed = 0.0005;
    const oscillationRange = 50;
    
    const updateScrollOffset = () => {
      targetOffset = (window.scrollY % window.innerHeight) * 0.2;
      
      const oscillation = Math.abs(Math.sin(Date.now() * oscillationSpeed)) * oscillationRange;
      targetOffset += oscillation;
      
      currentOffset += (targetOffset - currentOffset) * smoothFactor;
      
      document.documentElement.style.setProperty('--scroll-offset', currentOffset);
      
      requestAnimationFrame(updateScrollOffset);
    };

    updateScrollOffset();
    window.addEventListener('scroll', () => {
      targetOffset = (window.scrollY % window.innerHeight) * 0.2;
    });

    return () => {
      window.removeEventListener('scroll', updateScrollOffset);
    };
  }, []);

  return (
    <div className="min-h-screen">
      <div className="min-h-screen">
        <Navbar />
        <div className="mx-auto w-11/12 max-w-6xl pt-24">
          <div className="rounded-lg bg-background-light/80 backdrop-blur-sm p-6 shadow-xl">
            <Routes>
              <Route path="/" element={<GalleryPage />} />
              <Route path="/creative-bridge" element={<LeonardoPage />} />
              <Route path="/realistic-bridge" element={<HomePage />} />
              <Route path="/prompt-guide" element={<PromptGuide />} />
            </Routes>
          </div>
        </div>
        <CreditTracker />
      </div>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "*",
    element: <AppContent />,
  }
], {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
});

function App() {
  return <RouterProvider router={router} />;
}

export default App;
