import { useState, useEffect } from 'react';
import apiService from '../services/api';

export function useCredits() {
  const [credits, setCredits] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCredits = async () => {
    try {
      const creditInfo = await apiService.getCreditInfo();
      setCredits({
        creditsRemaining: creditInfo.creditsRemaining || 0,
        nextRefreshDate: creditInfo.nextRefreshDate
      });
    } catch (error) {
      console.error('Error fetching credits:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCredits();
    // Refresh credits every minute
    const interval = setInterval(fetchCredits, 60000);
    return () => clearInterval(interval);
  }, []);

  return {
    credits,
    loading,
    refreshCredits: fetchCredits
  };
} 