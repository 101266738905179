import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function Loader({ prompt }) {
  const [loadingText, setLoadingText] = useState('Analyzing your bridge...');
  
  const loadingPhrases = [
    'Analyzing your bridge...',
    'Preparing the foundation...',
    'Calculating dimensions...',
    'Designing the structure...',
    'Creating your covered bridge...',
    'Adding finishing touches...',
    'Almost there...',
  ];

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % loadingPhrases.length;
      setLoadingText(loadingPhrases[currentIndex]);
    }, 2000);

    return () => clearInterval(interval);
  }, [prompt]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900/80 backdrop-blur-sm"
    >
      <div className="flex flex-col items-center justify-center space-y-8">
        <motion.div
          className="grid grid-cols-3 gap-2"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          {[...Array(9)].map((_, i) => (
            <motion.div
              key={i}
              className="h-4 w-4 rounded-full bg-primary"
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.5, 1, 0.5],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                delay: i * 0.1,
                ease: "easeInOut"
              }}
            />
          ))}
        </motion.div>
        
        <motion.div
          className="flex flex-col items-center space-y-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <AnimatePresence mode="sync">
            <motion.p 
              key={loadingText}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="text-lg font-medium text-white"
            >
              {loadingText}
            </motion.p>
          </AnimatePresence>
          {prompt && (
            <motion.p 
              className="max-w-md text-center text-sm text-white/60"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              {prompt}
            </motion.p>
          )}
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Loader; 