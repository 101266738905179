import React from 'react';
import { useCredits } from '../hooks/useCredits';
import { motion } from 'framer-motion';
import { FiAlertCircle } from 'react-icons/fi';

function CreditTracker() {
  const { credits, loading } = useCredits();

  if (loading) {
    return (
      <div className="fixed bottom-4 right-4 bg-background-light p-4 rounded-lg shadow-lg">
        <p className="text-surface text-sm">Loading credits...</p>
      </div>
    );
  }

  // Format the date to show only the date part in a readable format
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date unavailable';
    }
  };

  const hasExtraCredits = credits.creditsRemaining > 3500;
  const hasLowCredits = credits.creditsRemaining < 100;
  
  // Calculate percentage for remaining credits
  const remainingPercentage = Math.min((credits.creditsRemaining / 3500) * 100, 100);

  // Format the credits display
  const formatCredits = () => {
    if (hasExtraCredits) {
      const baseCredits = 3500;
      const extraCredits = credits.creditsRemaining - baseCredits;
      return (
        <span className="text-surface text-sm font-medium">
          3500 <span className="text-green-500">+{extraCredits}</span>
        </span>
      );
    }
    return (
      <span className="text-surface text-sm font-medium">
        {credits.creditsRemaining} / 3500
      </span>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="fixed bottom-4 right-4 bg-background-light p-4 rounded-lg shadow-lg min-w-[250px]"
    >
      <div className="mb-3">
        <div className="flex justify-between items-center mb-1">
          <span className="text-surface text-sm">Credits Remaining</span>
          {formatCredits()}
        </div>
        <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
          <div
            className={`h-full rounded-full transition-all duration-300 ${
              hasLowCredits ? 'bg-red-500' : hasExtraCredits ? 'bg-green-500' : 'bg-primary'
            }`}
            style={{ width: `${remainingPercentage}%` }}
          />
        </div>
      </div>

      {hasLowCredits && (
        <div className="mb-2 flex items-center gap-1 text-red-500 text-xs">
          <FiAlertCircle className="w-3 h-3" />
          <span>Low credits available</span>
        </div>
      )}

      <div className="text-surface text-xs">
        Next refresh: {formatDate(credits.nextRefreshDate)}
      </div>
    </motion.div>
  );
}

export default CreditTracker; 