import React from 'react';
import { FiDownload, FiExternalLink } from 'react-icons/fi';

const DownloadOverlay = ({ isVisible, imageUrl, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="relative w-full max-w-md mx-4 rounded-lg bg-background p-4 md:p-6 text-surface shadow-xl">
        <div className="mb-3 md:mb-4 flex items-center justify-center space-x-2 md:space-x-3">
          <FiDownload className="h-5 w-5 md:h-6 md:w-6 animate-bounce" />
          <p className="text-base md:text-lg font-medium">Preparing your download...</p>
        </div>
        
        <p className="mb-3 md:mb-4 text-center text-xs md:text-sm text-surface/70">
          If the download doesn't start automatically,{' '}
          <a
            href={imageUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center text-primary hover:text-primary-dark"
          >
            click here to open in new tab
            <FiExternalLink className="ml-1 h-3 w-3 md:h-4 md:w-4" />
          </a>
        </p>

        <button
          onClick={onClose}
          className="mx-auto block rounded-md bg-surface/10 px-3 md:px-4 py-1.5 md:py-2 text-xs md:text-sm hover:bg-surface/20"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DownloadOverlay; 