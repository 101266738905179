import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';
import AtawalkLogo from '../assets/Atawalk-logo-dark-theme.png';

const navVariants = {
  hidden: { y: -20, opacity: 0 },
  visible: { 
    y: 0, 
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20,
      mass: 1
    }
  }
};

const linkVariants = {
  normal: { scale: 1 },
  hover: { 
    scale: 1.05,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 10
    }
  }
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const mobileMenuVariants = {
  closed: {
    opacity: 0,
    x: "100%",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  },
  open: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const Navbar = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <motion.nav
      className="sticky top-0 z-50 w-full bg-background/95 backdrop-blur-sm shadow-md border-b-2 border-primary/20"
      initial="hidden"
      animate="visible"
      variants={navVariants}
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="py-4">
          <div className="flex items-center justify-between">
            {/* Logo Section */}
            <motion.div 
              className="flex-shrink-0"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to="/" className="flex items-center">
                <motion.img 
                  src={AtawalkLogo} 
                  alt="Atawalk Logo" 
                  className="h-8 w-auto"
                  whileHover={{ x: 10 }}
                  transition={{ type: "spring", stiffness: 400 }}
                />
              </Link>
            </motion.div>

            {/* Desktop Navigation Links */}
            <div className="hidden sm:flex items-center">
              <AnimatePresence mode="sync">
                <motion.div 
                  className="flex space-x-4"
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                >
                  {['/', '/creative-bridge', '/realistic-bridge'].map((path) => (
                    <motion.div
                      key={path}
                      variants={linkVariants}
                      whileHover="hover"
                      whileTap={{ scale: 0.95 }}
                    >
                      <Link
                        to={path}
                        className={`rounded-md px-3 py-2 text-sm font-medium transition-colors ${
                          location.pathname === path
                            ? 'bg-accent text-background'
                            : 'text-surface hover:bg-accent/20 hover:text-surface'
                        }`}
                        onClick={closeMobileMenu}
                      >
                        {path === '/' ? 'Gallery' : 
                         path === '/creative-bridge' ? 'Creative Generation' : 
                         'Realistic Generation'}
                      </Link>
                    </motion.div>
                  ))}
                </motion.div>
              </AnimatePresence>
            </div>

            {/* Mobile Menu Button */}
            <div className="sm:hidden">
              <button
                onClick={toggleMobileMenu}
                className="p-2 rounded-md text-surface hover:bg-accent/20"
              >
                {isMobileMenuOpen ? (
                  <FiX className="h-6 w-6" />
                ) : (
                  <FiMenu className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>

          {/* Mobile Menu */}
          <AnimatePresence>
            {isMobileMenuOpen && (
              <motion.div
                className="sm:hidden"
                initial="closed"
                animate="open"
                exit="closed"
                variants={mobileMenuVariants}
              >
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {['/', '/creative-bridge', '/realistic-bridge'].map((path) => (
                    <Link
                      key={path}
                      to={path}
                      className={`block rounded-md px-3 py-2 text-base font-medium transition-colors ${
                        location.pathname === path
                          ? 'bg-accent text-background'
                          : 'text-surface hover:bg-accent/20 hover:text-surface'
                      }`}
                      onClick={closeMobileMenu}
                    >
                      {path === '/' ? 'Gallery' : 
                       path === '/creative-bridge' ? 'Creative Generation' : 
                       'Realistic Generation'}
                    </Link>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.nav>
  );
};

export default Navbar;
