import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiX, FiDownload, FiThumbsUp, FiThumbsDown } from 'react-icons/fi';
import apiService from '../services/api';
import DownloadOverlay from './DownloadOverlay';

const ImageModal = ({ 
  image, 
  isOpen, 
  onClose, 
  onLike, 
  onDislike,
  userInteractions = {},
  className = ''
}) => {
  const [downloadState, setDownloadState] = useState({
    isDownloading: false,
    currentImageUrl: null
  });

  if (!image) return null;

  const hasLiked = userInteractions[image._id]?.includes('like');
  const hasDisliked = userInteractions[image._id]?.includes('dislike');

  const handleDownload = async (imageUrl) => {
    try {
      setDownloadState({ isDownloading: true, currentImageUrl: imageUrl });
      await apiService.downloadImage(imageUrl);
    } catch (error) {
      console.error('Failed to download image:', error);
      // Handle error appropriately (show toast/notification)
    } finally {
      setTimeout(() => {
        setDownloadState({ isDownloading: false, currentImageUrl: null });
      }, 2000);
    }
  };

  return (
    <AnimatePresence mode="sync">
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 p-4"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className={`relative max-h-[90vh] w-full max-w-4xl overflow-y-auto rounded-lg bg-background p-3 md:p-4 ${className}`}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Close button */}
            <button
              onClick={onClose}
              className="absolute right-2 md:right-4 top-2 md:top-4 z-10 rounded-full bg-background/80 p-1.5 md:p-2 text-surface hover:bg-background-light"
            >
              <FiX className="h-5 w-5 md:h-6 md:w-6" />
            </button>

            {/* Image section */}
            <div className="relative mb-3 md:mb-4">
              <img
                src={image.imageUrl}
                alt={image.prompt}
                className="w-full rounded-lg"
              />
              
              {/* Download button */}
              <button
                onClick={() => handleDownload(image.imageUrl)}
                className="absolute bottom-2 md:bottom-4 right-2 md:right-4 rounded-full bg-primary p-1.5 md:p-2 text-surface hover:bg-primary-dark"
                title="Download Image"
              >
                <FiDownload className="h-4 w-4 md:h-5 md:w-5" />
              </button>
            </div>

            {/* Image details */}
            <div className="space-y-3 md:space-y-4 text-surface">
              <div className="flex items-center justify-between">
                <h3 className="text-lg md:text-xl font-bold">Bridge Details</h3>
                <div className="flex items-center gap-1 md:gap-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onLike(image._id);
                    }}
                    className={`flex items-center gap-1 rounded-full px-2 md:px-3 py-1 ${
                      hasLiked 
                        ? 'bg-green-500 text-white' 
                        : 'bg-background-light text-surface hover:bg-background-lighter'
                    }`}
                  >
                    <FiThumbsUp className="h-3 w-3 md:h-4 md:w-4" />
                    <span className="text-xs md:text-sm">{image.likes || 0}</span>
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onDislike(image._id);
                    }}
                    className={`flex items-center gap-1 rounded-full px-2 md:px-3 py-1 ${
                      hasDisliked 
                        ? 'bg-red-500 text-white' 
                        : 'bg-background-light text-surface hover:bg-background-lighter'
                    }`}
                  >
                    <FiThumbsDown className="h-3 w-3 md:h-4 md:w-4" />
                    <span className="text-xs md:text-sm">{image.dislikes || 0}</span>
                  </button>
                </div>
              </div>

              <div className="rounded-lg bg-background-light p-3 md:p-4">
                <h4 className="mb-1 md:mb-2 font-semibold text-sm md:text-base">Prompt</h4>
                <p className="text-xs md:text-sm">{image.prompt}</p>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-4">
                <div>
                  <h4 className="mb-1 md:mb-2 font-semibold text-sm md:text-base">Generation Settings</h4>
                  <ul className="space-y-0.5 md:space-y-1 text-xs md:text-sm">
                    <li>Style: {image.metadata.presetStyle}</li>
                    <li>Size: {image.metadata.width}x{image.metadata.height}</li>
                    <li>Steps: {image.metadata.num_inference_steps}</li>
                    <li>Guidance Scale: {image.metadata.guidance_scale}</li>
                  </ul>
                </div>
                <div>
                  <h4 className="mb-1 md:mb-2 font-semibold text-sm md:text-base">Bridge Properties</h4>
                  <ul className="space-y-0.5 md:space-y-1 text-xs md:text-sm">
                    {image.metadata.bridge_length && (
                      <li>Length: {image.metadata.bridge_length}</li>
                    )}
                    {image.metadata.bridge_material && (
                      <li>Material: {image.metadata.bridge_material}</li>
                    )}
                    {image.metadata.bridge_setting && (
                      <li>Setting: {image.metadata.bridge_setting}</li>
                    )}
                  </ul>
                </div>
              </div>

              <div className="text-xs text-surface/60">
                Created: {new Date(image.createdAt).toLocaleString()}
              </div>
            </div>
          </motion.div>
          <DownloadOverlay
            isVisible={downloadState.isDownloading}
            imageUrl={downloadState.currentImageUrl}
            onClose={() => setDownloadState({ isDownloading: false, currentImageUrl: null })}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ImageModal; 