import React from 'react';
import { Slider } from './ui/Slider';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/Select';

const LeonardoParams = ({ params, setParams }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setParams(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSliderChange = (name, value) => {
    setParams(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelectChange = (name, value) => {
    setParams(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const labelClasses = "block text-sm font-medium text-white mb-1";
  const helpTextClasses = "text-xs text-white/60";

  return (
    <div className="space-y-6">
      {/* Style Selection */}
      <div>
        <label className={labelClasses}>Preset Style</label>
        <Select value={params.presetStyle} onValueChange={(value) => handleSelectChange('presetStyle', value)}>
          <SelectTrigger>
            <SelectValue placeholder="Select style" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="DYNAMIC">Dynamic</SelectItem>
            <SelectItem value="CREATIVE">Creative</SelectItem>
            <SelectItem value="ENVIRONMENT">Environment</SelectItem>
            <SelectItem value="PHOTOGRAPHY">Photography</SelectItem>
            <SelectItem value="RENDER_3D">3D Render</SelectItem>
            <SelectItem value="RAYTRACED">Raytraced</SelectItem>
            <SelectItem value="SKETCH_BW">Sketch B&W</SelectItem>
            <SelectItem value="SKETCH_COLOR">Sketch Color</SelectItem>
            <SelectItem value="VIBRANT">Vibrant</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {/* Number of Images */}
      <div>
        <div className="flex justify-between items-center">
          <label className={labelClasses}>Number of Images (1-8)</label>
          <span className="text-sm text-white/80">{params.num_images}</span>
        </div>
        <div className="space-y-2">
          <Slider
            value={[params.num_images]}
            onValueChange={([value]) => handleSliderChange('num_images', value)}
            min={1}
            max={8}
            step={1}
            className="w-full"
          />
          <p className={helpTextClasses}>Number of variations to generate</p>
        </div>
      </div>

      {/* Guidance Scale Slider */}
      <div>
        <div className="flex justify-between items-center">
          <label className={labelClasses}>Guidance Scale (0.5-20)</label>
          <span className="text-sm text-white/80">{params.guidance_scale.toFixed(1)}</span>
        </div>
        <div className="space-y-2">
          <Slider
            value={[params.guidance_scale]}
            onValueChange={([value]) => handleSliderChange('guidance_scale', value)}
            min={0.5}
            max={20}
            step={0.1}
            className="w-full"
          />
          <p className={helpTextClasses}>How strongly the generation should reflect the prompt</p>
        </div>
      </div>

      {/* Inference Steps */}
      <div>
        <div className="flex justify-between items-center">
          <label className={labelClasses}>Inference Steps (10-60)</label>
          <span className="text-sm text-white/80">{params.num_inference_steps}</span>
        </div>
        <div className="space-y-2">
          <Slider
            value={[params.num_inference_steps]}
            onValueChange={([value]) => handleSliderChange('num_inference_steps', value)}
            min={10}
            max={60}
            step={1}
            className="w-full"
          />
          <p className={helpTextClasses}>Higher values may give better results but take longer</p>
        </div>
      </div>

      {/* PhotoReal Strength */}
      <div>
        <div className="flex justify-between items-center">
          <label className={labelClasses}>PhotoReal Strength</label>
          <span className="text-sm text-white/80">
            {params.photoRealStrength === 0.45 ? 'High' : 
             params.photoRealStrength === 0.5 ? 'Medium' : 'Low'}
          </span>
        </div>
        <div className="space-y-2">
          <Select 
            value={params.photoRealStrength.toString()} 
            onValueChange={(value) => handleSelectChange('photoRealStrength', parseFloat(value))}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select strength" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="0.55">Low</SelectItem>
              <SelectItem value="0.5">Medium</SelectItem>
              <SelectItem value="0.45">High</SelectItem>
            </SelectContent>
          </Select>
          <p className={helpTextClasses}>Controls the photorealistic quality of the generated image</p>
        </div>
      </div>

      {/* Image Dimensions */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className={labelClasses}>Width</label>
          <Select 
            value={params.width.toString()} 
            onValueChange={(value) => handleSelectChange('width', parseInt(value))}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select width" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="512">512px</SelectItem>
              <SelectItem value="640">640px</SelectItem>
              <SelectItem value="1024">1024px</SelectItem>
              <SelectItem value="1280">1280px</SelectItem>
              <SelectItem value="1536">1536px</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <label className={labelClasses}>Height</label>
          <Select 
            value={params.height.toString()} 
            onValueChange={(value) => handleSelectChange('height', parseInt(value))}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select height" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="512">512px</SelectItem>
              <SelectItem value="640">640px</SelectItem>
              <SelectItem value="1024">1024px</SelectItem>
              <SelectItem value="1280">1280px</SelectItem>
              <SelectItem value="1536">1536px</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Toggles */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="alchemy"
              checked={params.alchemy}
              onChange={handleChange}
              className="rounded border-white/20 bg-white/10"
            />
            <span className="text-sm text-white">Enable Alchemy</span>
          </label>
        </div>
        
        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="promptMagic"
              checked={params.promptMagic}
              onChange={handleChange}
              className="rounded border-white/20 bg-white/10"
            />
            <span className="text-sm text-white">Prompt Magic</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default LeonardoParams;
