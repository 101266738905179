import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FiThumbsUp, FiThumbsDown, FiDownload, FiMaximize2, FiChevronDown } from 'react-icons/fi';
import apiService from '../services/api';
import ImageModal from '../components/ImageModal';
import DownloadOverlay from '../components/DownloadOverlay';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/Select';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100
    }
  }
};

function GalleryPage() {
  const [images, setImages] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userInteractions, setUserInteractions] = useState({});
  const [browserFingerprint, setBrowserFingerprint] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState('newest');
  const [downloadState, setDownloadState] = useState({
    isDownloading: false,
    currentImageUrl: null
  });

  useEffect(() => {
    // Initialize fingerprint
    const initFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setBrowserFingerprint(result.visitorId);
    };

    initFingerprint();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await apiService.getRecentImages();
        setImages(sortImages(response.images, sortBy));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching images:', error);
        setLoading(false);
      }
    };

    fetchImages();
  }, [sortBy]);

  useEffect(() => {
    const fetchUserInteractions = async () => {
      if (!browserFingerprint || images.length === 0) return;

      try {
        const imageIds = images.map(img => img._id);
        const interactions = await apiService.getAnonymousInteractions(browserFingerprint, imageIds);
        
        // Format interactions by image ID
        const formattedInteractions = {};
        interactions.forEach(interaction => {
          if (!formattedInteractions[interaction.image_id]) {
            formattedInteractions[interaction.image_id] = [];
          }
          formattedInteractions[interaction.image_id].push(interaction.interaction_type);
        });
        
        setUserInteractions(formattedInteractions);
      } catch (error) {
        console.error('Error fetching user interactions:', error);
      }
    };

    fetchUserInteractions();
  }, [browserFingerprint, images]);

  const sortImages = (imagesToSort, sortOption) => {
    const imagesCopy = [...imagesToSort];
    switch (sortOption) {
      case 'newest':
        return imagesCopy.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      case 'oldest':
        return imagesCopy.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      case 'most_liked':
        return imagesCopy.sort((a, b) => {
          // First compare by likes
          const likeDiff = (b.likes || 0) - (a.likes || 0);
          if (likeDiff !== 0) return likeDiff;
          // If likes are equal, use points as a tiebreaker
          const pointsA = (a.likes || 0) - (a.dislikes || 0);
          const pointsB = (b.likes || 0) - (b.dislikes || 0);
          return pointsB - pointsA;
        });
      case 'most_disliked':
        return imagesCopy.sort((a, b) => {
          // First compare by dislikes
          const dislikeDiff = (b.dislikes || 0) - (a.dislikes || 0);
          if (dislikeDiff !== 0) return dislikeDiff;
          // If dislikes are equal, use negative points as a tiebreaker
          const pointsA = (a.likes || 0) - (a.dislikes || 0);
          const pointsB = (b.likes || 0) - (b.dislikes || 0);
          return pointsA - pointsB; // Note: reversed order for dislikes
        });
      default:
        return imagesCopy;
    }
  };

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    setImages(sortImages(images, newSortBy));
  };

  const handleInteraction = async (imageId, type) => {
    if (!browserFingerprint) return;

    try {
      // Check if the user has already interacted with this image
      const currentInteractions = userInteractions[imageId] || [];
      const hasLiked = currentInteractions.includes('like');
      const hasDisliked = currentInteractions.includes('dislike');

      // If clicking the same interaction type, remove it (toggle off)
      if ((type === 'like' && hasLiked) || (type === 'dislike' && hasDisliked)) {
        const success = await apiService.handleAnonymousInteraction(
          browserFingerprint,
          imageId,
          type
        );

        if (success) {
          // Remove the interaction
          setImages(images.map(img => {
            if (img._id === imageId) {
              return {
                ...img,
                [type === 'like' ? 'likes' : 'dislikes']: Math.max(0, (img[type === 'like' ? 'likes' : 'dislikes'] || 0) - 1)
              };
            }
            return img;
          }));

          setUserInteractions(prev => ({
            ...prev,
            [imageId]: currentInteractions.filter(t => t !== type)
          }));
        }
      } else {
        // If clicking a different interaction type, remove the old one and add the new one
        if (hasLiked || hasDisliked) {
          // First, remove the existing interaction
          const oldType = hasLiked ? 'like' : 'dislike';
          await apiService.handleAnonymousInteraction(
            browserFingerprint,
            imageId,
            oldType
          );

          // Update the count for the old interaction
          setImages(images.map(img => {
            if (img._id === imageId) {
              return {
                ...img,
                [oldType === 'like' ? 'likes' : 'dislikes']: Math.max(0, (img[oldType === 'like' ? 'likes' : 'dislikes'] || 0) - 1)
              };
            }
            return img;
          }));
        }

        // Add the new interaction
        const success = await apiService.handleAnonymousInteraction(
          browserFingerprint,
          imageId,
          type
        );

        if (success) {
          // Update the images with the new interaction count
          setImages(images.map(img => {
            if (img._id === imageId) {
              return {
                ...img,
                [type === 'like' ? 'likes' : 'dislikes']: (img[type === 'like' ? 'likes' : 'dislikes'] || 0) + 1
              };
            }
            return img;
          }));

          // Update user interactions
          setUserInteractions(prev => ({
            ...prev,
            [imageId]: [type]  // Replace any existing interactions with the new one
          }));
        }
      }
    } catch (error) {
      console.error('Error handling interaction:', error);
    }
  };

  const handleDownload = async (imageUrl) => {
    try {
      setDownloadState({ isDownloading: true, currentImageUrl: imageUrl });
      await apiService.downloadImage(imageUrl);
    } catch (error) {
      console.error('Failed to download image:', error);
    } finally {
      // Keep overlay visible for a moment after download starts
      setTimeout(() => {
        setDownloadState({ isDownloading: false, currentImageUrl: null });
      }, 2000);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="relative mb-4 md:mb-8">
        <h1 className="text-4xl md:text-5xl font-bold text-center text-white">
          Bridge Gallery
        </h1>
        
        {/* Sort Selector */}
        <div className="absolute right-0 top-1/2 -translate-y-1/2">
          <Select value={sortBy} onValueChange={handleSortChange}>
            <SelectTrigger className="w-[140px]">
              <SelectValue placeholder="Sort by" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="newest">Newest First</SelectItem>
              <SelectItem value="oldest">Oldest First</SelectItem>
              <SelectItem value="most_liked">Most Liked</SelectItem>
              <SelectItem value="most_disliked">Most Disliked</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      
      <motion.div 
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6"
        variants={container}
        initial="hidden"
        animate="show"
      >
        {images.map((image) => (
          <motion.div
            key={image._id}
            className="relative rounded-lg overflow-hidden bg-background-light group"
            variants={item}
            whileHover={{ scale: 1.02 }}
          >
            <div className="relative">
              <img
                src={image.imageUrl}
                alt={image.prompt}
                className="w-full h-48 sm:h-56 md:h-64 object-cover"
                onClick={() => setSelectedImage(image)}
              />
              
              {/* Hover overlay with quick actions */}
              <div className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center justify-center gap-2">
                <button
                  onClick={() => setSelectedImage(image)}
                  className="p-2 rounded-full bg-white/10 hover:bg-white/20 text-white"
                  title="Expand Image"
                >
                  <FiMaximize2 className="w-5 h-5" />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownload(image.imageUrl);
                  }}
                  className="p-2 rounded-full bg-white/10 hover:bg-white/20 text-white"
                  title="Download Image"
                >
                  <FiDownload className="w-5 h-5" />
                </button>
              </div>
            </div>

            {/* Image info and interactions */}
            <div className="p-3 md:p-4">
              <p className="text-surface text-xs md:text-sm mb-2 md:mb-3 line-clamp-2" title={image.prompt}>
                {image.prompt}
              </p>
              
              <div className="flex items-center justify-between text-xs md:text-sm">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleInteraction(image._id, 'like')}
                    className={`flex items-center gap-1 px-2 py-1 rounded ${
                      userInteractions[image._id]?.includes('like')
                        ? 'text-green-500'
                        : 'text-surface/60 hover:text-surface'
                    }`}
                  >
                    <FiThumbsUp className="w-4 h-4" />
                    <span>{image.likes || 0}</span>
                  </button>
                  <button
                    onClick={() => handleInteraction(image._id, 'dislike')}
                    className={`flex items-center gap-1 px-2 py-1 rounded ${
                      userInteractions[image._id]?.includes('dislike')
                        ? 'text-red-500'
                        : 'text-surface/60 hover:text-surface'
                    }`}
                  >
                    <FiThumbsDown className="w-4 h-4" />
                    <span>{image.dislikes || 0}</span>
                  </button>
                </div>
                <span className="text-surface/40 text-xs">
                  {new Date(image.createdAt).toLocaleDateString()}
                </span>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>

      <ImageModal
        image={selectedImage}
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        onLike={(id) => handleInteraction(id, 'like')}
        onDislike={(id) => handleInteraction(id, 'dislike')}
        userInteractions={userInteractions}
      />

      <DownloadOverlay
        isVisible={downloadState.isDownloading}
        imageUrl={downloadState.currentImageUrl}
        onClose={() => setDownloadState({ isDownloading: false, currentImageUrl: null })}
      />
    </div>
  );
}

export default GalleryPage; 